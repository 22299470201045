<template>
   <div>
    <div class="content-head">
        <el-button style="float: left; padding: 3px 4px;margin-right:10px;color:#FF6B3B" @click="$router.push({name: 'personCenter'})" icon="el-icon-caret-left" circle></el-button>
        <span class="card-head">主页</span>
    </div>
    <div style="display:flex;justify-content:space-between">
      <div class="ml10 mb8">
        <!-- {{nameList.length}}位联系人 -->
      </div>
      <!-- <el-button style="margin-bottom:5px" size="small">全部已读</el-button> -->
    </div>
    <el-card class="card">
      <div class="aui-resume-item">
           <!-- <div v-for="(item, index) in nameList" :key="index" style="margin-left:12px" @click="detail(item.username)">
              <div class="readState">
                  <div>
                    <i v-if="item.sex === '女'" class="iconfont icon-nvsheng iconSize ml10" />
                    <i v-else class="iconfont icon-nansheng iconSize ml10" />
                    <span class="ml10">{{item.username}}</span>
                  </div>
                  <div v-if="readFlag === '1'" class="redRound"></div>
              </div>
              <el-divider></el-divider>
           </div>  -->
            <!-- <el-button plain style="width:100%;margin-bottom:10px">
              {{item.name}}
            <el-tag :type="item.num > 0 ? 'warning' : 'success'">{{item.num}}</el-tag>
            </el-button> -->
        <div class="aui-flex aui-flex-clear">
          <div class="aui-flex-box b-line">
            <h3>常用联系人</h3>
          </div>
          <span></span>
        </div>
        <div class="aui-me-list">
          <div v-for="(item, index) in nameList" :key="index" class="aui-flex row-start b-line" @click="detail(item)">
            <div class="aui-img-sml">
              <img :src="item.image" alt="">
            </div>
            <div class="aui-flex-box showMouse">
              <p>{{item.nickname}}</p>
              <div v-if="unRead(item.openid)" class="redRound"></div>
              <!-- <span>什么时候可以完成这个项目啊</span> -->
            </div>
            <!-- <div class="aui-text">昨天</div> -->
          </div>
          <!-- <div href="javascript:;" class="aui-flex b-line" @click="detail()">
            <div class="aui-img-sml">
              <img src="../assets/user-logo-004.png" alt="">
            </div>
            <div class="aui-flex-box">
              <p>夏红</p>
              <span>今天晚上静默要上线</span>
            </div>
            <div class="aui-text">星期一</div>
          </div>
          <div href="javascript:;" class="aui-flex b-line" @click="detail()">
            <div class="aui-img-sml">
              <img src="../assets/user-logo-004.png" alt="">
            </div>
            <div class="aui-flex-box">
              <p>设计师小红</p>
              <span>今天晚上静默要上线</span>
            </div>
            <div class="aui-text">星期一</div>
          </div>
          <div href="javascript:;" class="aui-flex b-line" @click="detail()">
            <div class="aui-img-sml">
              <img src="../assets/user-logo-004.png" alt="">
            </div>
            <div class="aui-flex-box">
              <p>张洢豪</p>
              <span>今天晚上静默要上线</span>
            </div>
            <div class="aui-text">星期一</div>
          </div> -->
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { postAction, getAction } from '@/api/manage'

export default {
  data () {
    return {
      page: 1,
      name: '',
      massageList: [
        { name: '李大力', num: 1, time: '' },
        { name: '苏大强', num: 0, time: '' }
      ],
      list: [
        { name: '1' }
      ],
      nameList: [
        { username: '李大力', num: 1, time: '' },
        { username: '苏大强', num: 0, time: '' }
      ],
      listLoading: false,
      dialogVisible: false,
      form: {},
      readFlag:'',
      unreadList:[]
    }
  },
  mounted () {
    this.box()
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
  },
  methods: {
    toMatch () {

    },
    detail (item) {
      this.$router.push({ name: 'MessageDetail', query: { username: item.openid,image:item.image } })
    },
    unRead (id) {
       if(this.unreadList.includes(id)){
        return 1
       } else {
        return 0
       }
    },
    box () {
      const url = '/message/box'
      postAction(url,{id:this.openid}).then(res => {
        console.log('res',res)
        this.nameList = res.data.msg.UserDetail
        this.unreadList = res.data.msg.unread
        // this.readFlag = res.data.msg.code
      })
    }
  }
}
</script>
<style scoped>
  .fr{
     align-self:flex-end
  }
  .fl{
     align-self:flex-start
  }
  .content-head{
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    padding: 0px 20px;
    background-image:url("../assets/head-bg.png");
    background-size:cover;
    color: white;
    font-size: 14px;
    font-weight: 600;
  }
  .body-item{
    display:flex;
    justify-content:space-between
  }
  .small-font{
    font-size: 12px;
    color: #686868;
    margin-right:20px;
  }
  .iconSize{
    font-size: 40px;
  }
  .readState{
    position: relative; 
    display: flex;
  }
  .redRound{
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 5px;
    position: absolute;
    top:4px;
    left: 12px;
  }
  .right-corner{
    display: inline-block;
    width: 16px;
    height: 16px;
    font-size: 8px;
    position:absolute;
    left:-18px;
    top:-8px;
    text-align: center;
    background-color: #FDEDBE;
    border:2px solid #D2EDC8;
    border-radius:10px
  }

img {
    width:100%;
    height:auto;
    display:block;
    border: 0;
}


html, body, div, dl, dt, dd, ol, ul, li, h1, h2, h3, h4, h5, h6, p, blockquote, pre, button, fieldset, form, input, legend, textarea, th, td {
    margin: 0;
    padding: 0;
}


img {
    border: 0;
}

button,input,optgroup,select,textarea {
    margin: 0;
    font: inherit;
    color: inherit;
    outline: none;
}

li {
    list-style: none;
}

a {
    color: #666;
}

.clearfix::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}

.clearfix {
}


.divHeight {
    width: 100%;
    height: 10px;
    background: #f5f5f5;
    position: relative;
    overflow: hidden;
}

.r-line {
    position: relative;
}

.r-line:after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    height: 100%;
    border-right: 1px solid #D9D9D9;
    -webkit-transform: scaleX(0.5);
    transform: scaleX(0.5);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.b-line {
    position: relative;
}

.b-line:after {
    content: '';
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #e2e2e2;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
}


.aui-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 15px;
    position: relative;
}

.aui-flex-box {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    text-align: left;
    flex: 1;
    min-width: 0;
    font-size: 14px;
    color: #333;
}


/* 必要布局样式css */



.icon {
    width: 20px;
    height: 20px;
    display: block;
    border: none;
    float: left;
    background-size: 20px;
    background-repeat: no-repeat;
}

.icon-return{
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAACh0lEQVRoQ+3ZMW8TMRQH8PcOsTBQISExVCIRCwgWFvZ+hZYBpIrEcTYkBsTGlI2VDamL3YEBpWJoR1BJaOlXYEzyDYp0bMRGRjnJoDuWs/1e3EbKeHf55b33vzsb4YJ98IJ54RKce8XZV1gIcQ8AviDiD2vtC631pzZFYQ2WUt611p4CwM0VcqqU2soSXIN1ztdKqTfZgRuw75RSz9tg3bHsWjomlh04NpYVOAWWDbjf7z8oimLipbH7bUopJdvO7L/Hk8+wwyLiCSLe8H6cww4BwGYFTo0lbWkKLBmYCksCrsNaa99rrZ/FmFnS0GrCdrvd3mg0MqEDqu58yVKaAzZZSwshHgLAsX/rcW2csrJVtaNXeIWdIuL16qJU2OgVrsMCwEGn03mSamaThVYTtizLp+PxeJkioJKFFldslJbmjA0O5o4NCm7AHpZluUM5s1FCSwjxCAA++7ceADiczWaPJ5PJL6qAihJaUspdY8w+Il7xLsASG6SlhRA/EfGa91BxNJ/Pd7hVNtiT1mAwOAeADXdCa637Hi0Wi3zBUsptY8wHRLzqwIh/nlbzbWmna0roLEOrmo11QQd9W1oHdFDwOrR3cLCH/uuFn0uQRQE7dMPKJHl6RwNzRUcF/w9N9VIRHVyhazbLDihWP5KAHbphOzQ5OhmYCzopmAM6OZgaTQL20FMAuOUtHESfaTLwCn3HWnuWEk0KpkCTg1OjWYArtDHmKyJu+jMdeh+KDdghh8NhZ7lcfvPRoXcaWYFToNmBY6NZgj20m+nb3pr3W631yzY7GWzBDtXr9TaLojjz0N+VUvezBXvoPQBw0Fda649Zg9vg6o5l3dKhse58l+AY/yqnc/4GvNDoTFOq8FwAAAAASUVORK5CYII=");

}



.aui-search-box{
    background:#fff;
    border-radius:3px;
    position:relative;
}

.aui-search-box input{
    text-align:center;
    width:100%;
    font-size:0.8rem;
    padding:0.4rem 0;
}

.icon-search{
    background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAFJElEQVRoQ+2aTVYbORCAS213b8OcIHCCkBMEThDmBDOcIPEC6bEKWfEkL4ATjOcEIScYOEGcE0xygiFbA13zyk/t15ZLLclu446DVjwQUn+qH1WVSsAvNsQvxgvPwNsu8bVI+Pz8fDfLsjdCiAMA2AWAfQDYYQ7zBhHvhBA3AHArpRyv+8BbAybIXq/3FhHfCyEIMnkg4jcAuC7L8ur09JR+bn2sDGxBPwDAny1/3Y0Q4uPJyQlJv7WxNPDFxcXOw8PDO0Q8i/ia7wAwJzFE3BFCvIr431Ge54PBYHAXMTc4ZSlgY8w+In7yqS4ifiXVFEJch+zSrnVg7f2t54vvEPFYKXUdJApMSAY2xpDq/sWti4h/l2V5tqz9WWdH65MfeOHugYiXSqnBKtBJwMPh8AOnwquCugBkLpPJhKDJN7hjJKU8XhY6GtgYcwkA7+obIeKPLMuO2nYs1R5W3UeMrS8NHQXMqTHZaVEUB205E5/ErLQvhRB/OHOWgg4CD4fDA0T8x5Hsk8DW99Rak6Rd6GMp5ShFvRuB6XTv7+//rUdJTyVZDoKBpqvqMHQT1NdqBGY2+J7n+f661bhJYsYYCkTe1ObcSCkPY6XsBeZUGQBep5xm7EekzLM2/c25tqJV2wtsjCG7peC/GldSyvcpH7euua4TpRhcKbUXsx8L7EqXrp+iKHY3qcouDKPaUVJmgV3bRcSPSqmYmDnmkFuZw5jcWEr5OrQ4C2yM+a/yzF2UbgWltR7Xg5LHx8e9UFi7AKy1PhJCfKoWpbBRKdV26hcSRNTftdYUfl7UvjWoiQvAbgiJiL+3kaVEESROsrk4xQnVoKpJ3dEurMgBz91zeZ7/1iVnxTgvyrNfVr+XUjbGFhww1lTkq1KK6lGdHcYYypFneXTIjueAGRX5LKU86iwtAGitz+pppBDisCl7mwNm7t+gE9j0YTCZXON9/NMDpwrpGbie+3YxwnJNiLmLG6/RRgkDQGcSBp+vWMlp0aLGmNm1ZJ8/Gi/yDjituVpbkpemj9da01vPtESaknZtCtzNmkKBEhd4JF3kmwKlfW0JihKd6aDyUyhQ4pIHNyAfKKVIbTo33EQnxudwEqZQ8kuNLirP3MRpMHl7MNHx5cNzAXkoPt0ELFdRDdkvfaev4uGqdedyYvc6is3bWWBPZXDjFctKkzjpxmqhN3d0TxAAkuq/61RzrTU91c6yuFjpelW6cvmTyWSu/tuFUJN754qVbiOwjboW3oJDkcw6JUuviQBA9fJZg0yqEIKPaW5FAQCS33PaOATrV744XQfBGpa7dxDYOghqJ5rVjTYB7WZFBJKiyhV4ENiqNvV0UFeN24YQVe1vQ8IcMAAk7x8F3ASNiNdFURw/RWWTMS/6tCToaOCApCnDuuz3+1frBLd2TJrmtjtFQycBE7TttKGWJK7HitqLRst00jGdfGxLgw869sEgGbiyRyYwmTNVyqVtDyU5vGkPpZTyttIUAHiBiNM+TNujtVD/9l2BHuio22Np4EravV6PeizqL/Jt+KjpGk1e2BP+BqFXAq7IbKmUHst9nXSph3ArhDgLtUPZtib39miEbgW4orH2Ta+PFOcmSb1qVyzLchR68qyfng86z/M9zoG2CuyK0Up+19oqWFulaWPqk6YAJsuycb/fH6/i3T3Q4zzPD9111wqcqserzPc04SxUa7YGODbZ2SpgF5raNcqy3K/7hK0DJmhS77IsqQd7oV97K4GbfMEz8Cqe8mf43/8BI1hVaoEimFMAAAAASUVORK5CYII=');
    position:absolute;
    width:16px;
    height:16px;
    margin-left:-35px;
    left:50%;
    top:0.4rem;
    background-size:16px;
}

.aui-palace-one{
    background:#fff;
    padding:0.5rem 1rem;
}

.aui-palace-one .aui-palace-grid{
    width:25%;
}

.aui-palace-one h2{
    font-size:0.7rem;
}

.aui-palace-one  .aui-palace-grid-icon{
    width:35px;
    height:35px;
}

.aui-flex-clear{
    background:#fff;
}

.aui-flex-clear h3{
    font-weight:normal;
    font-size:0.9rem;
}

.aui-flex-clear span{
    color:#868686;
    font-size:0.8rem;
}

.aui-img-sml{
    width:35px;
    height: 35px;
    margin-right:0.5rem;
}

.aui-me-list{
    background:#fff;
}
.aui-img-sml img{
    border-radius:100%;
}

.aui-flex-box span{
    color:#777777;
    font-size:0.75rem;
}

.aui-text{
    font-size:0.7rem;
    color:#afafaf;
}

</style>
